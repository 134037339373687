.flex {
  flex: 1;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.center-a {
  justify-content: center;
}

.center-b {
  align-items: center;
}

.end-b {
  align-items: flex-end;
}

.flex-end {
  justify-content: flex-end;
}
.flex-start {
  justify-content: flex-start;
}

.container-login-wallpaper {
  background-image: url('../../assets/images/background_login.jpg');
  background-size: cover;
}

.date-picker-box {
  border: 1px solid #d3d3d3;
  border-radius: 40px;
  display: inline-block;
  margin: 0 auto;
  justify-content: center;
}

.date-picker-box div {
  margin: 0 auto;
  max-inline-size: 18ch;
  padding-left: 3%;
}

.date-picker-separator {
  line-height: 50px;
  display: inline-block;
  position: relative;
  transform: translateY(1em);
  padding: 5px;
  font-weight: bold;
  font-size: 25;
}

.download-table-xls-button {
  border-radius: 10px;
  color: white;
  background-color: #61b7c3;
  position: absolute;
  transform: translateY(1em);
  font-size: 2ch;
  padding: 2ch;
  padding-left: 4ch;
  padding-right: 4ch;
  right: 1ch;
  box-shadow: none;
  border: none;
}

.download-table-xls-button:hover {
  filter: brightness(1.1);
}

.download-table-xls-button-flex {
  border-radius: 10px;
  color: white;
  background-color: #61b7c3;
  transform: translateY(1em);
  font-size: 2ch;
  padding: 2ch;
  padding-left: 4ch;
  padding-right: 4ch;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.download-table-xls-button-flex:hover {
  filter: brightness(1.1);
}
